
import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import RoleChecker from './checkers/RoleChecker';

import App from './containers/App/App';

// getComponent is a function that returns a promise for a component
// It will not be called until the first mount
function asyncComponent(getComponent) {
  return class AsyncComponent extends React.Component {
    static Component = null;
    state = { Component: AsyncComponent.Component };

    constructor() {
      super();

      getComponent().then(Component => {
        AsyncComponent.Component = Component;
        this.setState({ Component });
      });
    }

    render() {
      const { Component } = this.state;
      if (Component) {
        return <Component {...this.props} />;
      }
      return null;
    }
  }
}

const TestPage = asyncComponent(() =>
  import('./containers/TrainDataPage/TestPage').then(module => module.default)
);
const TrainDataPage = asyncComponent(() =>
  import('./containers/TrainDataPage/TrainDataPage').then(module => module.default)
);
const LoginPage = asyncComponent(() =>
  import('./containers/TrainDataPage/LoginPage').then(module => module.default)
);

const ProjectDashboardPage = asyncComponent(() =>
  import('./containers/ProjectDashboardPage/ProjectDashboardPage').then(module => module.default)
);

const Dashboard = asyncComponent(() =>
  import('./containers/LscDashboard/Dashboard').then(module => module.default)
);

const Dashboard4IFrame = asyncComponent(() =>
  import('./containers/LscDashboard4IFrame/Dashboard').then(module => module.default)
);

const DocumentAIoV = asyncComponent(() =>
  import('./containers/Documents/AIoV').then(module => module.default)
);

export default function () {
  return (
    <Router>
      <App>
        <Switch>
          <Route exact path="/dashboard/:vehicle" component={Dashboard4IFrame} />
          <Route exact path="/dashboard" component={Dashboard4IFrame} />
          <Route exact path="/monitor/:vehicle/:isShowVehicleInfo" component={Dashboard4IFrame} />
          <Route exact path="/monitor/:vehicle" component={Dashboard4IFrame} />
          <Route exact path="/monitor" component={Dashboard4IFrame} />
          <Route exact path="/doc/:page" component={DocumentAIoV} />
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </App>
    </Router>
  );
}
