import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './Header.css';
import headerLogo from 'design/images/logo-aigo.png'
import imgTaipei101 from 'design/images/taipei101.png'
import {getUser} from "../../../api/Auth";
import LinearLayout from '../../../components/common/LinearLayout';
import Button from "react-bootstrap/lib/Button";

export default class Header extends Component {

  logout() {
    localStorage.clear();
    window.location.href = './test'
  }

  onClickTest() {
    window.location.href = './test'
  }

  onClickTrain() {
    window.location.href = './train'
  }

  onClickLogo() {
    window.location.href = './test'
  }

  onClickText() {
    window.location.href = './login'
  }

  render() {
    const {
      headerLabel,
      children,
    } = this.props;

    const email = localStorage.getItem("email")
    const pathname = window.location.pathname

    // render menu when user is authorized
    return (
      <header className={ (!email | email == null) ? "Header-dark" : "Header-dark" }>
        {/* <h1 className="Header-label">{headerLabel}</h1> */}
        <LinearLayout orientation={'horizontal'} align={'left'}>
          <img src={ headerLogo } onClick={this.onClickLogo.bind(this)} />
          <div>
            <div className={'demo-link-hover demo-link-selected'} style={{fontSize: "16pt", color: "#ffffff", marginLeft: "20px"}} onClick={this.onClickText.bind(this)}>
              Text2SQL
            </div>
          </div>
          {email && pathname !== "/login" && pathname === "/train" && <LinearLayout style={{marginLeft: "50px"}} orientation={'horizontal'} align={'left'}>
            <LinearLayout orientation={'horizontal'} align={'center'} style={{fontSize: "16pt", height:"60px", width: "300px",  color: "#000000", background: "#ffffff", marginTop: "50px"}}>
              <a href={'./train'}>訓練資料標註</a>
            </LinearLayout>
            <LinearLayout orientation={'horizontal'} align={'center'} style={{fontSize: "16pt",height:"60px",  width: "200px",  color: "#ffffff", marginTop: "40px", marginLeft: "5px"}}>
              <a style={{color: "#ffffff"}} href={'./test'}>專家模式</a>
            </LinearLayout>
          </LinearLayout>}
          {email &&  pathname !== "/login" && pathname === "/test" && <LinearLayout style={{marginLeft: "50px"}} orientation={'horizontal'} align={'left'}>
            <LinearLayout orientation={'horizontal'} align={'center'} style={{fontSize: "16pt", height:"60px", width: "300px",  color: "#ffffff", marginTop: "40px"}}>
              <a style={{color: "#ffffff"}} href={'./train'}>訓練資料標註</a>
            </LinearLayout>
            <LinearLayout orientation={'horizontal'} align={'center'} style={{fontSize: "16pt", height:"60px", width: "200px",  color: "#000000", background: "#ffffff", marginTop: "50px", marginLeft: "5px"}}>
              <a href={'./test'}>專家模式</a>
            </LinearLayout>
          </LinearLayout>}
        </LinearLayout>

        <LinearLayout orientation={'vertical'} align={'right'} style={{fontSize: "14pt", color: "#ffffff", marginRight: "20px"}}>
          {email && <div>
            Hi, {email}
          </div>}

          { email && <div className={'demo-link-hover demo-link-selected'} style={{fontSize: "16px", height: "30px", color: "#ffffff"}} onClick={this.logout.bind(this)}>登出</div>}
          <div style={{fontSize: "8pt", color: "#ffffff"}}>
            <div>v1.0.1 20211010</div>
            {!email && <div style={{fontSize: "14px", color: "#ffffff"}} onClick={this.onClickText.bind(this)} className={'demo-link-hover demo-link-selected'} >登入</div>}
          </div>
        </LinearLayout>}
        {/*<LinearLayout orientation={'vertical'} align={'center'}>*/}
        {/*  <di>*/}
        {/*    {email}*/}
        {/*  </di>*/}
        {/*  {email}*/}
        {/*{ email && <Button style={{fontSize: "16px"}} onClick={this.logout.bind(this)}>登出</Button>}*/}
        {/*</LinearLayout>*/}
      </header>
    );
  }
}

