import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { initAction } from 'redux/reducers/app';

import Header from 'containers/App/Header/Header';
import HeaderDetail from 'containers/App/Header/HeaderDetail';
import InProgress from './InProgress';
import {log} from '../../shared/util/logger'

import './App.scss';


@connect(
  (state) => {
    const app = state.app;
    return {
      initSuc: app.get('initSuc'),
    };
  },
  {
    initAction,
  }
)

class App extends React.Component {
  static propTypes = {
    initSuc: PropTypes.bool.isRequired,
    initAction: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selfKernel: undefined,
      progressDisabled: this.isProgressDisabled(props),
      showVersionUpgrade: false,
    };
    // this.onError = this.onError.bind(this);
    this.overwriteWeekFunction();
    // window.onerror = this.onWindowError;
  }

  overwriteWeekFunction() {
    Date.prototype.getWeek = function (dowOffset) {
      dowOffset = typeof(dowOffset) == 'int' ? dowOffset : 0; //default dowOffset to zero
      var newYear = new Date(this.getFullYear(),0,1);
      var day = newYear.getDay() - dowOffset; //the day of week the year begins on
      day = (day >= 0 ? day : day + 7);
      var daynum = Math.floor((this.getTime() - newYear.getTime() -
        (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
      var weeknum;
      //if the year starts before the middle of a week
      if(day < 4) {
        weeknum = Math.floor((daynum+day-1)/7) + 1;
        if(weeknum > 52) {
          nYear = new Date(this.getFullYear() + 1,0,1);
          nday = nYear.getDay() - dowOffset;
          nday = nday >= 0 ? nday : nday + 7;
          /*if the next year starts before the middle of
            the week, it is week #1 of that year*/
          weeknum = nday < 4 ? 1 : 53;
        }
      }
      else {
        weeknum = Math.floor((daynum+day-1)/7);
      }
      return weeknum;
    };
  }

  isProgressDisabled(props) {

    const path = props.location && props.location.pathname ? props.location.pathname:undefined;
    if (!path) {
      return false;
    }

    if (path.indexOf("/desktop/") !== -1) {
      return true;
    }

    if (path.indexOf("/register/") !== -1) {
      return true;
    }

    if (path.indexOf("/training/") !== -1) {
      return true;
    }

    if (path.indexOf("/signup") !== -1) {
      return true;
    }

    if (path.indexOf("/login") !== -1) {
      return true;
    }

    return false;
  }
  //
  // onError(error, scriptURI, lineNumber, columnNumber, info) {
  //   const bug = {
  //     error: error,
  //     info: {
  //       scriptURI,
  //       lineNumber,
  //       columnNumber,
  //       info,
  //     }};
  //
  //   console.error("onError#", bug);
  //
  //   this.setState({
  //     bug: bug,
  //     showError: true,
  //   });
  //   return true;
  // };
  //
  // onWindowError(error, scriptURI, lineNumber, columnNumber, info) {
  //   const bug = {
  //     error: error,
  //     info: {
  //       scriptURI,
  //       lineNumber,
  //       columnNumber,
  //       info,
  //     }};
  //
  //   console.error("onWindowError#error:", error);
  //   console.error("onWindowError#info:", info);
  // };

  componentWillReceiveProps(nextProps) {
  }

  componentDidMount() {
    this.props.initAction();
  }

  componentDidCatch(error, info) {
    // this.onError("global error", "", "", "", error);
  }

  renderPage() {
    const { initSuc } = this.props;
    log("initSuc", initSuc);
    return (<div>
      {initSuc || this.state.progressDisabled ? this.props.children : <InProgress />}
    </div>)
  }

  render() {
    return (
      <Fragment>
        <link href="https://fonts.googleapis.com/css?family=Roboto+Condensed" rel="stylesheet"></link>
        {/*<Header headerLabel="">*/}
        {/*  <HeaderDetail/>*/}
        {/*</Header>*/}
        {this.renderPage()}
      </Fragment>
    );
  }
}

export default withRouter(App)
